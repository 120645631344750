$family-primary: 'Lexend Deca', sans-serif
$family-secondary: 'Oxygen', sans-serif
$navbar-dropdown-arrow: hsl(0, 0%, 4%)

@import 'bulma/bulma'

html
  scroll-behavior: smooth

.is-size-huge
  font-size: 16rem !important

+mobile
  .is-reversed-mobile
    display: flex
    flex-direction: column-reverse
    .column:last-child
      flex: none
      width: 100%
